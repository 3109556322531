@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&family=Roboto:wght@100;300;400;500;700&display=swap");
/* @import url('https://fonts.cdnfonts.com/css/helvetica-neue-9'); */

/* * {
  scroll-behavior: smooth;
} */

html {
  font-size: 10px;
}

body {
  margin: 0px;
}

.inactiveClass {
  color: grey;
  display: flex;
  text-decoration: none;
  background-color: none;
  margin: 8px 16px;
  border-bottom: 2px solid transparent;
  border-radius: 8px;
  /* margin: 20px 10px; */
}

.inactiveClass:hover {
  color: rgba(208, 210, 255, 1);
  display: flex;
  text-decoration: none;
  background-color: none;
  margin: 8px 16px;
  border-bottom: 2px solid transparent;
  border-radius: 8px;
  /* margin: 20px 10px; */
}

.activeClass,
.activeClass:hover {
  /* 
  
  
  
  border-radius: 15px; */
  color: black;
  margin: 8px 16px;
  font-size: 2rem;
  border-radius: 8px;
  border-bottom: 2px solid #6d72f6;
  display: flex;
  text-decoration: none;
  background-color: #fff;
  box-shadow: 0px 2px 40px 0px rgba(17, 6, 38, 0.08);
}

.icons_enlarge {
  transform: scale(1);

  transition-duration: 0.5s;
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.gm-style .gm-style-iw-c {
  padding: 0px;
}

.gm-ui-hover-effect {
  display: none !important;
}

.icons_enlarge:hover {
  transform: scale(1.12);
  transition-duration: 0.5s;
  /* background-color: blue; */
}

.highlight {
  border: 1.5px solid #e8e8e8;
  border-radius: 12px;
}

.highlight:hover {
  border: 1.5px solid #4f44e0;
  /* border-radius: 12px */
}

.sectionHeader {
  padding: 8px 12px;
}

.section {
  padding: 12px;
}

.heading {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: 700;
  color: #22105c;
}

.MuiTableCell-root {
  padding: 2px !important;
}

.MuiToolbar-root {
  min-height: 50px !important;
  padding: 0px 0px 0px 0px !important;
}

.mytable {
  position: relative;
  height: 100%;
}

.MuiAutocomplete-root .MuiAutocomplete-inputRoot {
  padding-right: 0px !important;
}

.multiple_Auto.MuiAutocomplete-root .MuiAutocomplete-inputRoot {
  padding: 2px 0px 2px 5px !important;
}

.multiple_Auto_Checkbox.MuiAutocomplete-root .MuiAutocomplete-inputRoot {
  padding: 2px 0px 2px 5px !important;
}

#vaidehi .MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  margin-left: 0px !important;
}

input#vaidehi.MuiInputBase-input.MuiOutlinedInput-input.MuiInputBase-inputSizeSmall.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd.MuiAutocomplete-input.MuiAutocomplete-inputFocused {
  margin-left: 0px !important;
}

.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root {
  padding-right: 0px !important;
}

.stickyDiv {
  position: -webkit-sticky;
  position: sticky;
  top: 57px;
  background-color: #fff;
  padding: 5px 0px;
  font-size: 25px;
  z-index: 999;
}

/* ul.MuiAutocomplete-listbox
{
  background-color: #22105C;
} */
/* .MuiAutocomplete-popper.MuiPopperUnstyled-root
{
  background-color: pink !important;
} */

.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator {
  transform: rotate(0deg) !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root {
  background-color: rgb(28, 28, 28) !important;
  top: -40px !important;
  right: 0px !important;
  left: -10px !important;
  border-radius: 4px !important;
  height: max-content !important;
}

.MuiSnackbarContent-message {
  padding: 0px !important;
}

.apexcharts-point-annotation-marker:hover {
  cursor: pointer;
}

.apexcharts-pie-area {
  cursor: pointer;
}

.apexcharts-marker {
  cursor: pointer;
}

.mytable .cell {
  text-align: center;
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-60%);
}

#overflow-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
}

h1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
}

h1:hover {
  overflow: visible;
}

#overflow-text-1 {
  width: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#overflow-text-2 {
  width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#overflow-text-3 {
  width: 66.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

#overflow-text-4 {
  width: 70.5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.apexcharts-tooltip {
  background-color: #fff;
}

.apexcharts-toolbar {
  background-color: #fff;
  padding: 0px !important;
}

.bizcharts-tooltip.g2-tooltip {
  border-radius: 5px !important;
  border: 1px solid #e3e3e3 !important;

  box-shadow: 2px 2px 6px -4px #999 !important;
  padding: 0px !important;
  /* cursor: default;
    font-size: 14px; */
  /* left: 62px;
    opacity: 0; */
  /* pointer-events: none;
    position: absolute; */
  /* top: 20px; */
  /* display: flex;
    flex-direction: column;
    overflow: hidden;
    white-space: nowrap;
    z-index: 12; */
  /* transition: 0.15s ease all; */
}

.bizcharts-tooltip-title {
  background: #eceff1;
  border-bottom: 1px solid #ddd;
  padding: 6px;
  margin-bottom: 4px;
}

.bizcharts-tooltip-series-group {
  padding: 5px 10px;
  /* display: none; */
  text-align: left;
  justify-content: left;
  align-items: center;
  padding-bottom: 4px;
}

.apexcharts-menu-item.exportCSV {
  display: none !important;
}

.middle-div {
  position: relative;
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.apexcharts-selection-rect + g .svg_select_points_l,
.apexcharts-selection-rect + g .svg_select_points_r {
  cursor: ew-resize;
  opacity: 1;
  visibility: hidden !important;
}

.apexcharts-legend {
  justify-content: center !important;
}

.apexcharts-canvas .apexcharts-zoom-icon.apexcharts-selected svg,
.apexcharts-canvas .apexcharts-selection-icon.apexcharts-selected svg,
.apexcharts-canvas .apexcharts-reset-zoom-icon.apexcharts-selected svg {
  fill: #6d72f6 !important;
}

.apexcharts-pan-icon.apexcharts-selected svg {
  stroke: #6d72f6 !important;
}

#lastLabel .apexcharts-xaxis-label:nth-last-child(1) {
  /* transform: translateX(-70px) */
  display: none;
}

.apexcharts-legend-series {
  display: flex;
}

#pie-legends-largeWidth .apexcharts-legend-text {
  max-width: 160px;
}

#hideAlternateLabel .apexcharts-xaxis-label:nth-last-child(2n) {
  display: none;
  /* &:nth-child(5n){ display:revert; } */
}

#googleTimeline text {
  fill: #666666;
  font-size: 12px;
  font-weight: 400;
  font-family: "Roboto";
}

.MuiAutocomplete-endAdornment {
  top: 8px !important;
}

.MuiSelect-select.MuiTablePagination-select.MuiSelect-standard.MuiInputBase-input {
  color: #4f44e0;
  font-weight: 600;
}

.MuiAutocomplete-root .MuiInput-root .MuiInput-input {
  font-size: "12px !important";
}

.ant-notification {
  z-index: 1500 !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation6.MuiSnackbarContent-root {
  box-shadow: none !important;
  top: 259px;
  right: 425px;
  position: absolute;
  width: max-content;
  background-color: #fff;
  height: 22px;
  min-width: max-content;
  border-radius: 12px;
}

.MuiSnackbarContent-action {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 5px !important;
}

.slick-prev:before {
  color: #4f44e0 !important;
}

.slick-next:before {
  color: #4f44e0 !important;
}

.slick-track > * {
  /* background-color: #6D72F6; */
  display: flex !important;
  justify-content: center !important;
}

.BatteryPack > * > * > * > * {
  display: unset !important;
  justify-content: unset !important;
}

/* svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.MuiTableSortLabel-icon.MuiTableSortLabel-iconDirectionAsc.css-12lfbf6-MuiSvgIcon-root-MuiTableSortLabel-icon
{
  opacity: 1 !important;
} */

/* table */

.ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
  background: rgb(251, 251, 250);
}

.antd-table-custom-class thead th,
.antd-table-custom-class tbody td {
  white-space: nowrap;
  word-break: break-word;
  word-break: break-all;
}

li[id^="vaidehi-option"].MuiAutocomplete-option {
  /* background-color: pink !important; */
  display: flex !important;
  justify-content: center !important;
}

.LandingPageTable .ant-table-body {
  min-height: 500px;
}

.LandingPageTable.Selection .ant-table-thead > tr > th:nth-child(2) {
  pointer-events: none !important;
}

.LandingPageTable.NoSelection .ant-table-thead > tr > th:nth-child(1) {
  pointer-events: none !important;
}

/* .ant-table-thead > tr > th:nth-child(2) {
  pointer-events: none !important;
} */

.LandingPageTable_FleetBatteryHealth .ant-table-body {
  min-height: 700px;
}

.LandingPageTable_FleetBatteryHealth .ant-table-thead > tr > th:nth-child(1) {
  pointer-events: none !important;
}

.SpeificBatteryPageTable .ant-table-body {
  min-height: 300px;
}

.CartTable .ant-table-body {
  min-height: 430px;
}

.CartTable .ant-table-thead > tr > th:nth-child(2) {
  pointer-events: none !important;
}

.CartTable .ant-table-row {
  cursor: default !important;
}

.ant-checkbox .ant-checkbox-inner {
  border-color: #000;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #4f44e0;
}

.ant-table-wrapper td.ant-table-column-sort {
  background: #fff;
}

.ant-table-body::-webkit-scrollbar {
  width: 9px;
  height: 9px;
  /* width of the entire scrollbar */
}

.antTableHeader {
  cursor: move;
  width: 100%;
  display: flex;
}

/* .antTableHeader:hover
{
  background-color: pink !important;
} */
.ant-table-column-sorters {
  cursor: pointer !important;
  /* background-color: red !important; */
}

.ant-table-column-sorters > span:not(.ant-table-column-title):hover {
  background-color: green;
  cursor: cell;
}

/* .ant-table-column-sorters span.ant-table-column-sorter.ant-table-column-sorter-full {
  background-color: red !important;
} */
.ant-table-column-sorters
  span.ant-table-column-sorter.ant-table-column-sorter-full:hover {
  background-color: blueviolet !important;
}

/* .ant-table-column-sorter.ant-table-column-sorter-full
{
  background-color: pink;
  cursor:  pointer !
} */
.ant-table-thead > tr > th {
  cursor: move !important;
  padding: 8px 8px !important;
}

.ant-table-thead > tr > th > div {
  cursor: move !important;
}

.ant-table-column-sorter {
  display: none;
}

.ant-table-wrapper .ant-table-column-title {
  cursor: move;
}

/* .antTableSort
  {
    padding: 0px 0px;
  }*/
.antTableSort:hover {
  background-color: #e5e5e5;
  border-radius: 5px;
  cursor: pointer;
  display: inline;
  justify-content: center;
}

.ant-table-thead > tr > th:first-child.ant-table-selection-column {
  cursor: default !important;
}

/* .undragableColumn {
  pointer-events: none !important;
} */

.sortIconDiv {
  display: inline;
  text-align: center;
  position: relative;
  /* top: 2px; */
  cursor: pointer;
  margin-block: -4px;
  margin-inline: -2px -4px;
  padding: -1px 3px;
  /* box-sizing: border-box;
  width: 18px; */
  border-radius: 5px;
  height: 38px;
  /* font-size: 13px;
  /* padding: 2px 2px; */

  /* //height: 40px; */
}

.sortIconDiv:hover {
  background: #e5e5e5;
}

.sortIcon {
  color: rgba(0, 0, 0, 0.29);
  cursor: pointer;
}

.sortIcon.up {
  position: relative;
  /* left: 4px;
  top: -5px; */
  color: rgba(0, 0, 0, 0.29);
  top: 6px;
}

.sortIcon.down {
  position: relative;
  /* left: -9px;
  bottom: -3px; */
  color: rgba(0, 0, 0, 0.29);
  bottom: 6px;
}

.sortIcon.active {
  color: #4f44e0;
}

#treemap .apexcharts-datalabel {
  fill: #000 !important;
  font-size: 12px;
}

.ant-table-body::-webkit-scrollbar-thumb {
  border: 2px solid white;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.3);
  /* roundness of the scroll thumb */
  /* border: 3px solid orange; */
  /* creates padding around scroll thumb */
}

.ant-pagination {
  font-size: 13px;
}

.ant-select-single .ant-select-selector {
  font-size: 13px;
}

.renderID {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ant-picker {
  border: 1px solid rgb(189, 189, 189);
}

.ant-picker-input > input::placeholder {
  color: rgb(150, 150, 150) !important;
}

.copyIcon {
  cursor: pointer;
  margin: 0 15px;
}

.rowClass {
  cursor: pointer;
}

.specificBattery-kpi {
  background-color: white;
  border-radius: 20px;
  border: 1px solid #e1e1e1;
  padding: 0.9rem;
  /* cursor: pointer; */
  /* box-shadow: rgba(0, 0, 0, 0.07) 2.0px 2.0px 0px; */
}

.specificBattery-kpi-disabled {
  background-color: white;
  border-radius: 20px;
  border: 1px solid #ececec;
  padding: 0.9rem;
  cursor: default;
  box-shadow: rgba(0, 0, 0, 0.07) 2px 2px 0px;
}

/* .apexcharts-xaxis-label:nth-last-child(2) {
  transform: translateX(-40px)
} */
/* .specificBattery-kpi:hover {
  border: 1px solid #4f44e0;
} */

.ant-picker-presets > ul > li:active {
  background-color: #473dca;
}

.specificBattery-kpiContainer {
  display: grid;
  gap: 0.9rem;
  grid-template-columns: repeat(6, 140px);
  justify-content: flex-end;
  padding: 0 0.5rem 0.5rem;
}

.mapSlider_open {
  transition: width 0.2s ease-in;
}

.button_hover {
  /* background-color:white; */
  height: 23.5px;
  color: #fff;
  line-height: 25px;
  padding: 5px;
  border-radius: 4px;
  margin-left: 3px;
  /* border: 1px solid #e8e8e8; */
  cursor: pointer;
  font-size: 12.5px;
}

.map_cancel_button {
  /* background-color:white; */
  height: 23.5px;
  color: #4f44e0;
  line-height: 25px;
  padding: 5px;
  border-radius: 4px;
  margin-left: 3px;
  border: 1px solid #4f44e0;
  cursor: pointer;
  font-size: 12.5px;
}

.button_hover:hover {
  text-decoration: underline;
  /* background-color: red; */
}

.mapSlider_close {
  width: 0;
  transition: width 0.3s ease-in;
}

.specificBattery-kpiContainer::-webkit-scrollbar {
  width: 2px !important;
  height: 8px;
  /* background-color:#fff; */
}

.specificBattery-kpiContainer::-webkit-scrollbar-thumb {
  /* background:rgb(250, 250, 250); */
  border-radius: 5px;
}

.profile_Icon {
  cursor: pointer;
  border-radius: 4px;
}

.profile_Icon:hover {
  background: #473dca;
}

.infowindowAlerts_open {
  max-height: 100px;
  /* transition: max-height 1s ease-in; */
}

.infowindowAlerts_closed {
  height: 0px;
  max-height: 0px;
  transition: max-height 1s ease-out;
}

/* .css-10nakn3-MuiModal-root-MuiPopover-root-MuiMenu-root
{
  z-index: 10001 !important;
} */

.filterDateRange {
  display: flex;
}

@media only screen and (max-width: 1000px) {
  .filterDateRange {
    display: inline;
  }
}

.responsive_Images {
  width: 80%;
  height: auto;
}

.view {
  animation: target-fade 3s;
}

@keyframes target-fade {
  from {
    background-color: #c3c5ff;
  }

  to {
    background-color: transparent;
  }
}

::-webkit-scrollbar {
  width: 9px;
  height: 9px;
}

::-webkit-scrollbar-thumb {
  border: 2px solid white;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.3);
}

/* ul.MuiList-root.MuiList-padding.MuiList-root
{
} */
/* ul.MuiList-root ::-webkit-scrollbar-thumb {
  border: 2px solid white;
    border-radius: 4px;
    background-color: pink !important;
} */
/* ul.MuiList-root.MuiList-padding.css-zv5wdl-MuiList-root ::-webkit-scrollbar-thumb {
  border: 2px solid rgb(2, 2, 246);
    border-radius: 4px;
    background-color: rgba(255, 0, 0);
} */

/* .multiple_Auto.MuiAutocomplete-root .MuiAutocomplete-inputRoot
::-webkit-scrollbar-thumb {
  border: 2px solid white;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .3);
} */
/* ul.MuiAutocomplete-listbox
{
  max-height: 400px;
  background-color: #e8e8e8;
  overflow-y: scroll;
}
ul.MuiAutocomplete-listbox
::-webkit-scrollbar-track {
  border: 2px solid white;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .3);
} */

/* .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiAutocomplete-paper
::-webkit-scrollbar-thumb {
  border: 2px solid white;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, .3);
} */

/* ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
}
::-webkit-scrollbar-thumb {
  background: #775DD0;
}

::-webkit-scrollbar-thumb:hover {
  background: #c4c1c1;
} */
